@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

a,
input,
button {
  @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-400 focus-visible:ring-offset-2 focus-visible:ring-offset-neutral-50 dark:focus-visible:ring-neutral-600 dark:focus-visible:ring-offset-neutral-900;
}


.carousel {
  overflow: hidden;
}
.carousel-container {
  display: flex;
}
.carousel-item {
  flex: 0 0 100%;
  min-width: 0;
}

.eq-height {
  padding-bottom: 99999px;
  margin-bottom: -99999px;
  float: left;
}

